import React, { Component } from 'react'
import './style.css'

class Unsubscribed extends Component {

  render() {
    return (
      <div className="component">
        <p className="description-text">
          Unsubscribed
        </p>
      </div>
    )
  }
}

export default Unsubscribed
