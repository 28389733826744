import React, { Component } from 'react'
import { match } from 'react-router'
import './style.css'
import { verifyHash, isError, unsubscribe } from '../../services'
import { History } from 'history'

interface urlParms {
  email: string
}

interface Props {
  match: match<urlParms>
  history: History
}

interface State {
  email: string
  error: boolean
}

class Unsubscribe extends Component<Props, State> {

  constructor(props: Props) {
    super(props)
    this.state = {
      email: '',
      error: false
    }
  }

  componentDidMount() {
    this.parseEmail()
  }

  parseEmail = async () => {
    const res = await verifyHash(this.props.match.params.email)
    if (!isError(res)) this.setState({ email: res.email })
    else this.setState({ error: true })
  }

  unsubscribe = async () => {
    const res = await unsubscribe(this.props.match.params.email)
    if (!isError(res)) this.props.history.push('/unsubscribed')
    else this.setState({ error: true })
  }

  render() {
    return !this.state.error 
    ? (
        <div className="component">
          <p className="description-text">
            You won't be able to receive reminder emails for {this.state.email}
          </p>
          <button className="button" onClick={this.unsubscribe}>Unsubscribe</button>
        </div>
      )
    : (
        <p className="error-text">Unexpected error occur</p>
      )
  }
}

export default Unsubscribe
