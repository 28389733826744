import React, { Component } from 'react'
import logo from './logo.svg'
import './App.css'
import { BrowserRouter as Router, Route } from "react-router-dom"
import Unsubscribe from './components/unsubscribe/unsubscribe'
import Unsubscribed from './components/unsubscribe/unsubscribed'

class App extends Component {
  
  render() {
    return (
      <Router>
        <div className="app">
          <img src={logo} />
          <Route exact path="/unsubscribed" component={Unsubscribed} />
          <Route exact path="/unsubscribe/:email" component={Unsubscribe} />
        </div>
      </Router>
    )
  }
}

export default App
