const BASE_URL = process.env.REACT_APP_ORIGIN

interface VerifiedEmail {
  email: string
}

interface Error {
  error: string
}

type METHODS = 'POST' | 'PUT' | 'GET'

export const isError = (res: any): res is Error => !!res.error

const request = (method: METHODS, url: string, reqBody?: Object) =>
  fetch(`${BASE_URL}/public/v1/email-subscription/${url}`, {
    method: method,
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(reqBody),
  })
  .then(res => {
    const ct = res.headers.get('content-type')
    if (!!ct && ct.includes('application/json')) return res.json()
    else return res.text()
  })

export const verifyHash = async (hash: string): Promise<VerifiedEmail | Error> =>
  request('POST', 'verify', { hash })

export const unsubscribe = async (hash: string): Promise<string | Error> =>
  request('PUT', 'unsubscribe', { hash })